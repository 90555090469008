<div class="p-fluid">
  <h1>Dynamic Config Management</h1>

  <!-- Buttons for Loading and Refreshing -->
  <div class="flex mb-3">
    <button
      pButton
      label="Load Configs"
      icon="pi pi-refresh"
      class="p-button-outlined mr-2"
      (click)="loadConfigs()"
    ></button>
    <button
      pButton
      label="Refresh Cache"
      icon="pi pi-sync"
      class="p-button-outlined"
      (click)="refreshConfigs()"
    ></button>
  </div>

  <!-- Add New Config Form -->
  <p-card header="Add New Config">
    <form (ngSubmit)="addConfig()">
      <div class="field">
        <label for="key">Key</label>
        <input id="key" [(ngModel)]="newConfig.key" name="key" class="p-inputtext" required />
      </div>
      <div class="field">
        <label for="value">Value</label>
        <ng-container *ngIf="newConfig.type === 'boolean'; else nonBooleanValueInput">
          <br />
          <p-inputSwitch [(ngModel)]="newConfig.value" name="value"></p-inputSwitch>
        </ng-container>
        <ng-template #nonBooleanValueInput>
          <input
            id="value"
            [(ngModel)]="newConfig.value"
            name="value"
            class="p-inputtext"
            required
          />
        </ng-template>
      </div>
      <div class="field m-b2">
        <label for="type">Type</label>
        <p-dropdown
          id="type"
          [(ngModel)]="newConfig.type"
          (ngModelChange)="onTypeChange($event)"
          name="type"
          [options]="typeOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Select Type"
        ></p-dropdown>
      </div>
      <div class="field" class="mb-2">
        <label for="description">Description</label>
        <input
          id="description"
          [(ngModel)]="newConfig.description"
          name="description"
          class="p-inputtext"
          required
        />
      </div>
      <button pButton type="submit" label="Add Config" icon="pi pi-plus"></button>
    </form>
  </p-card>

  <!-- Config List in Cards -->
  <div class="grid mt-3">
    <div class="col-4 card-container" *ngFor="let config of configs">
      <p-card class="card-content">
        <div class="">
          <span class="font-bold mr-2">{{ config.key }}</span>
          <br />
          <span class="p-tag p-tag-rounded" [ngClass]="getTypeClass(config.type)">
            {{ config.type }}
          </span>
        </div>
        <p class="font-italic">{{ config.description }}</p>

        <!-- Boolean Switch -->
        <div *ngIf="config.type === 'boolean'; else nonBooleanInput" class="field">
          <label for="value">Value</label><br />
          <p-inputSwitch
            [(ngModel)]="config.value"
            (onChange)="updateBooleanConfig(config)"
          ></p-inputSwitch>
        </div>

        <!-- Non-Boolean Inputs -->
        <ng-template #nonBooleanInput>
          <div class="field">
            <label for="value">Value</label>
            <input
              id="value"
              type="text"
              class="p-inputtext p-component"
              [(ngModel)]="config.value"
              (blur)="updateConfig(config)"
            />
          </div>
        </ng-template>
      </p-card>
    </div>
  </div>
</div>

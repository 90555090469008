<div class="quote-list-container">
  <h3 class="text-lg md:text-3xl m-1">Alle Preisformulare</h3>
  <ng-container>
    <p-table
      #quoteTable
      [value]="quotes"
      dataKey="id"
      [lazy]="true"
      (onLazyLoad)="loadQuotes($event)"
      [paginator]="true"
      [rows]="quotesMeta.take"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Preisangeboten"
      [rowsPerPageOptions]="[3, 20, 50, 100]"
      [loading]="loading"
      [globalFilterFields]="['offer_number', 'postalCode', 'name', 'email', 'clientId']"
      [totalRecords]="quotesMeta.itemCount"
      styleClass="p-datatable-sm p-datatable-striped responsive-table"
      [rowGroupMode]="'subheader'"
      groupRowsBy="email"
      [expandedRowKeys]="expandedRows"
    >
      <ng-template pTemplate="caption">
        <div class="flex sm:flex-row justify-content-between align-items-center gap-1">
          <div class="flex justify-content-end gap-2">
            <p-button
              label="Klar"
              class="p-button-outlined p-button-responsive"
              icon="pi pi-filter-slash"
              (click)="clearSearch(quoteTable)"
              [outlined]="true"
            ></p-button>

            <p-button
              label="Expand All"
              icon="pi pi-plus"
              class="p-button-outlined p-button-responsive"
              (onClick)="expandAll()"
              [outlined]="true"
            />
            <p-button
              label="Collapse All"
              icon="pi pi-minus"
              class="p-button-outlined p-button-responsive"
              (onClick)="collapseAll()"
              [outlined]="true"
            />
          </div>
          <span class="p-input-icon-left w-full sm:w-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="quoteTable.filterGlobal($any($event.target).value, 'contains')"
              placeholder="Suche"
              class="w-full"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="customerNumberHeader">
            <span class="mobile-hidden">Kundennr.</span>
          </th>
          <th class="mobile-hidden">
            Angebotsnr.
            <!--pSortableColumn="offerNumber"  <p-sortIcon field="offerNumber"></p-sortIcon> -->
          </th>
          <th style="min-width: 137px">
            Datum
            <!--
            pSortableColumn="createdAt" <p-sortIcon field="createdAt"></p-sortIcon> -->
          </th>
          <th>
            Name
            <!--  pSortableColumn="name" <p-sortIcon field="name"></p-sortIcon></th> -->
          </th>

          <th class="mobile-hidden">
            E-mail
            <!--pSortableColumn="email" <p-sortIcon field="email"></p-sortIcon> -->
          </th>
          <th>PLZ</th>
          <th style="min-width: 47px">
            <span class="mobile-hidden">Bear. | Ges. </span>
            <span class="desktop-hidden">Bear. Ges.</span>
          </th>
          <th class="mobile-hidden" style="min-width: 97px">IP | Quelle</th>
          <th class="actions">
            <span class="mobile-hidden">Aktionen</span>
          </th>
        </tr>
      </ng-template>

      <!-- Group Header Template with PrimeNG Row Toggler -->
      <ng-template pTemplate="groupheader" let-quote let-expanded="expanded">
        <tr [ngStyle]="{ 'background-color': expandedRows[quote.email] ? 'rgb(57, 59, 62)' : '' }">
          <ng-container *ngIf="getFirstQuoteByEmail(quote.email) as firstQuote">
            <td>
              <ng-container *ngIf="hasMultipleQuotes(quote.email)">
                <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="quote"
                  class="p-button-text p-button-rounded p-button-plain no-padding"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                >
                  &nbsp;
                </button>
              </ng-container>
              <span class="mobile-hidden"> {{ firstQuote.client.id }}</span>
              <i
                *ngIf="firstQuote.extraInfo?.includes('TYLKO DANE KONTAKTOWE')"
                class="pi pi-info-circle ml-2"
                style="color: orange"
              ></i>
            </td>
            <td (click)="editQuote(firstQuote.id)" class="mobile-hidden">
              {{ firstQuote.offerNumber }}
            </td>
            <td (click)="editQuote(firstQuote.id)">
              {{ firstQuote.createdAt | date: 'd/M/yy, H:mm' }}
            </td>
            <td (click)="editQuote(firstQuote.id)">
              {{ firstQuote.name }} {{ firstQuote.surname }}
            </td>
            <td (click)="editQuote(firstQuote.id)" class="mobile-hidden">{{ firstQuote.email }}</td>
            <td (click)="editQuote(firstQuote.id)">{{ firstQuote.postalCode }}</td>
            <td (click)="editQuote(firstQuote.id)">
              <!-- Display Tick for quoteEdited -->
              <i
                class="pi"
                [ngClass]="{
                  'pi-check': checkGroupStatus(quote.email, 'quoteEdited'),
                  'pi-times': !checkGroupStatus(quote.email, 'quoteEdited')
                }"
                [ngStyle]="{
                  color: checkGroupStatus(quote.email, 'quoteEdited') ? 'green' : '#b53e3e'
                }"
              ></i>

              <span class="mobile-hidden">&nbsp;|&nbsp;</span>

              <!-- Display Tick for quoteSent -->
              <i
                class="pi"
                [ngClass]="{
                  'pi-check': checkGroupStatus(quote.email, 'quoteSent'),
                  'pi-times': !checkGroupStatus(quote.email, 'quoteSent')
                }"
                [ngStyle]="{
                  color: checkGroupStatus(quote.email, 'quoteSent') ? 'green' : '#b53e3e'
                }"
              ></i>
            </td>
            <td (click)="editQuote(firstQuote.id)" class="mobile-hidden">
              <span>[{{ firstQuote.countryCode }}] | </span>
              {{ firstQuote.source }}
            </td>
            <td>
              <div class="flex justify-content-between align-items-center">
                <i
                  class="pi pi-file-edit cursor-pointer mobile-hidden"
                  style="font-size: 1.5rem"
                  (click)="editQuote(firstQuote.id)"
                ></i>
                <i
                  class="pi pi-trash cursor-pointer"
                  style="font-size: 1.5rem"
                  (click)="showDeleteConfirmDialog(firstQuote)"
                ></i>
              </div>
            </td>
          </ng-container>
        </tr>
      </ng-template>

      <!-- Body Template -->
      <ng-template pTemplate="rowexpansion" let-quote>
        <tr class="data-row">
          <td (click)="editQuote(quote.id)">
            <span class="ml-4 mobile-hidden"> {{ quote.client.id }}</span>
            <i
              *ngIf="quote.extraInfo?.includes('TYLKO DANE KONTAKTOWE')"
              class="pi pi-info-circle ml-2"
              style="color: orange"
            ></i>
          </td>
          <td (click)="editQuote(quote.id)" class="mobile-hidden">{{ quote.offerNumber }}</td>
          <td (click)="editQuote(quote.id)">{{ quote.createdAt | date: 'd/M/yy, H:mm' }}</td>
          <td (click)="editQuote(quote.id)">{{ quote.name }} {{ quote.surname }}</td>
          <td (click)="editQuote(quote.id)" class="mobile-hidden">{{ quote.email }}</td>
          <td (click)="editQuote(quote.id)">{{ quote.postalCode }}</td>
          <td (click)="editQuote(quote.id)">
            <i
              class="pi"
              [ngClass]="{
                'pi-check': quote.status.quoteEdited && quote.status.quoteEdited[0]?.date,
                'pi-times': !quote.status.quoteEdited || !quote.status.quoteEdited[0]?.date
              }"
              [ngStyle]="{
                color:
                  quote.status.quoteEdited && quote.status.quoteEdited[0]?.date
                    ? 'green'
                    : '#b53e3e'
              }"
            ></i>

            <span class="mobile-hidden">&nbsp;|&nbsp;</span>

            <i
              class="pi"
              [ngClass]="{
                'pi-check': quote.status.quoteSent && quote.status.quoteSent[0]?.date,
                'pi-times': !quote.status.quoteSent || !quote.status.quoteSent[0]?.date
              }"
              [ngStyle]="{
                color:
                  quote.status.quoteSent && quote.status.quoteSent[0]?.date ? 'green' : '#b53e3e'
              }"
            ></i>
          </td>
          <td (click)="editQuote(quote.id)" class="mobile-hidden">
            <span>[{{ quote.countryCode }}] | </span>
            {{ quote.source }}
          </td>
          <td>
            <div class="flex justify-content-between align-items-center">
              <i
                class="pi pi-file-edit cursor-pointer mobile-hidden"
                style="font-size: 1.5rem"
                (click)="editQuote(quote.id)"
              ></i>
              <i
                class="pi pi-trash cursor-pointer"
                style="font-size: 1.5rem"
                (click)="showDeleteConfirmDialog(quote)"
              ></i>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="9">No search results.</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</div>

<p-confirmDialog />

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBase } from '../utils/http-base';

export interface ConfigEntry {
  key: string;
  value: string | number | boolean;
  type: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateConfigEntry {
  key: string;
  value: string;
  type: 'string' | 'boolean' | 'number';
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class DynamicConfigService {
  private readonly apiEndpoint = 'config';

  constructor(private http: HttpBase) {}

  /**
   * Fetch all configurations
   * @returns Observable<ConfigEntry[]>
   */
  getAllConfigs(): Observable<ConfigEntry[]> {
    return this.http.get<ConfigEntry[]>(this.apiEndpoint);
  }

  /**
   * Create or update a configuration entry
   * @param config Config entry to set
   * @returns Observable<ConfigEntry>
   */
  setConfig(config: CreateConfigEntry): Observable<ConfigEntry> {
    return this.http.post<ConfigEntry>(this.apiEndpoint, config);
  }

  /**
   * Refresh the configuration cache
   * @returns Observable<void>
   */
  refreshCache(): Observable<void> {
    return this.http.patch<void>(`${this.apiEndpoint}/refresh`, {});
  }
}
